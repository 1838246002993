<route>
{
  "meta": {
    "auth": "vendorEdit"
  }
}
</route>

<template>
  <div>
    <el-card class="container">
      <el-form ref="form" :rules="rules" class="vendorFrom" label-position="right" :model="form" label-width="160px">
        <!-- <el-form-item label="商家上架" prop="requestSources">
          <el-checkbox-group v-model="form.requestSources">
            <el-checkbox label="1">微信小程序</el-checkbox>
            <el-checkbox label="2">H5</el-checkbox>
            <el-checkbox label="3">后台</el-checkbox>
            <el-checkbox label="4">抖音小程序</el-checkbox>
          </el-checkbox-group>
        </el-form-item> -->
        <el-form-item label="商家LOGO" prop="businessLogo">
          <img class="img" v-if="isCheck" :src="form.businessLogo" />
          <span v-else>
            <bm-upload type="system" v-model="form.businessLogo"></bm-upload>
            <span class="assist">建议尺寸360*360</span></span
          >
        </el-form-item>
        <el-form-item label="商家名称" prop="businessTitle">
          <span v-if="isCheck">{{ form.businessTitle }}</span>
          <el-input v-else v-model.trim="form.businessTitle" @blur="handleCheckTitle"></el-input>
        </el-form-item>
        <el-form-item label="一句话介绍" prop="businessBrief">
          <span v-if="isCheck">{{ form.businessBrief }}</span>
          <el-input v-else v-model.trim="form.businessBrief"></el-input>
        </el-form-item>
        <el-form-item label="地区" prop="areaIds">
          <el-select
            multiple
            class="city"
            :disabled="$store.getters['vendor/params/getVendorId'] === '' && currentAreaId != -1"
            placeholder="请选择城市"
            v-model="form.areaIds"
          >
            <el-option v-for="item in areaList" :key="item.id" :label="item.name" :value="item.id" :disabled="item.dis">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="商家分类" prop="businessCategoryIds">
          <el-cascader
            :disabled="isCheck"
            :options="options"
            v-model="form.businessCategoryIds"
            :props="props"
            @change="handleChangeBusinessCategoryId"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item label="海报二维码类型" prop="posterQrcodeType">
          <el-radio-group v-model="form.posterQrcodeType">
            <el-radio :label="1">公众号码</el-radio>
            <el-radio :label="2">小程序码</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="浏览人数" prop="baseVitualBusinessVisitors">
          <span v-if="isCheck">{{ form.baseVitualBusinessVisitors }}</span>
          <span v-else>
            <el-input-number
              v-model="form.baseVitualBusinessVisitors"
              controls-position="right"
              :min="0"
            ></el-input-number>

            <bm-tip>当前浏览人数：XX，修改后添加浏览数将在此基础上叠加</bm-tip>
          </span>
        </el-form-item>
        <el-form-item label="商家背景" prop="businessEnvironmentImage">
          <img class="img" v-if="isCheck" :src="form.businessEnvironmentImage" />
          <span v-else>
            <bm-upload type="system" v-model="form.businessEnvironmentImage" :format="imageAccept"></bm-upload>
            <span class="assist">建议尺寸702*560</span>
          </span>
        </el-form-item>

        <el-form-item label="收款方式" prop="businessModel">
          <el-radio-group v-model="form.businessModel">
            <el-radio
              :label="0"
              :disabled="form.productCount !== 0 && $store.getters['vendor/params/getVendorId'] !== ''"
              >二级商户号</el-radio
            >
            <el-radio
              :label="1"
              :disabled="form.productCount !== 0 && $store.getters['vendor/params/getVendorId'] !== ''"
              >普通收款</el-radio
            >
          </el-radio-group>
        </el-form-item>
        <el-form-item label="商家资质">
          <span v-if="isCheck">
            <img class="img" v-for="item in form.businessQualifications" :src="item" :key="item" />
          </span>
          <span v-else>
            <bm-upload type="businessLicense" v-model="form.businessQualifications" :limit="1000"></bm-upload
          ></span>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" v-if="!isCheck" @click="onSubmit">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <business-title :showList="showList" :list="businessList" @set-dialog="handleShowList" />
  </div>
</template>

<script>
import businessTitle from './__com__/business'
export default {
  data() {
    return {
      options: [],
      props: {
        multiple: true,
        value: 'id',
        label: 'businessCategoryTitle',
        children: 'child'
      },
      rules: {
        // requestSources: [
        //   {
        //     required: true,
        //     message: '请选择商家上架',
        //     trigger: 'change',
        //     type: 'array',
        //     min: 1
        //   }
        // ],
        businessLogo: [{ required: true, message: '请上传logo', trigger: 'change' }],
        businessTitle: [{ required: true, message: '请输入商家名称', trigger: 'blur' }],
        areaIds: [{ type: 'array', required: true, message: '请选择地区', trigger: 'blur' }],
        businessCategoryIds: [{ type: 'array', required: true, message: '请选择商家分类', trigger: 'change' }],
        businessBrief: [{ required: true, message: '请输入一句话介绍', trigger: 'blur' }],
        baseVitualBusinessVisitors: [{ required: true, message: '请上传浏览人数', trigger: 'blur' }],
        businessEnvironmentImage: [{ required: true, message: '请上传商家背景', trigger: 'change' }],
        posterQrcodeType: [{ required: true, message: '请选择海报二维码类型', trigger: 'change' }],
        businessModel: [{ required: true, message: '请选择收款方式', trigger: 'change' }]
      },

      isCheck: false,
      form: {
        businessId: null,
        requestSources: [],
        businessLogo: '',
        businessTitle: '',
        posterQrcodeType: 1,
        businessCategoryIds: [],
        businessBrief: '',
        baseVitualBusinessVisitors: '',
        businessEnvironmentImage: '',
        areaIds: [],
        businessModel: 0,
        businessQualifications: []
      },
      areaList: [],
      currentAreaId: '',
      showList: false,
      businessList: [],
      imageAccept: ['png', 'jpg', 'jpeg', 'bmp']
    }
  },
  components: {
    'business-title': businessTitle
  },
  async created() {
    this.currentAreaId = sessionStorage.currentAreaId
    this.getBusinessCategory()
    this.areaList = this.$store.getters['user/userAreaInfoListWithoutGlobal'].map(item => {
      delete item.dis
      return item
    })
    if (this.$store.getters['vendor/params/getVendorId'] !== '') {
      await this.getVendor()
    } else {
      if (JSON.parse(sessionStorage.currentAreaId) === -1) {
        this.form.areaIds.push(this.$store.getters['user/userAreaInfoListWithoutGlobal'][0].id)
      } else {
        this.form.areaIds.push(JSON.parse(sessionStorage.currentAreaId))
      }
    }
    console.log(this.$store.getters['vendor/params/getVendorCheck'])
    if (this.$store.getters['vendor/params/getVendorCheck']) {
      this.isCheck = true
      this.rules = {}
    }
  },
  methods: {
    handleShowList(bool) {
      this.showList = bool
    },
    handleChangeBusinessCategoryId() {
      let arr = []

      this.form.businessCategoryIds.forEach(item => {
        arr.push(item[0])
      })

      let uniArr = Array.from(new Set(arr))

      let arr2 = []
      if (uniArr.length === 2) {
        this.form.businessCategoryIds.forEach(item => {
          if (item[0] == uniArr[0]) {
            arr2.push(item)
          }
        })
        this.$message.error('一级分类只能单选')
        this.form.businessCategoryIds = arr2
      }
    },

    // 获取商家分类
    getBusinessCategory() {
      const list = this.$api.vendor
        .vendor('businessCategoryAll')
        .then(res => {
          this.options = this.treeData(res)
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
    },
    treeData(data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].child.length === 0) {
          data[i].child = undefined
        } else {
          this.treeData(data[i].child)
        }
      }

      return data
    },
    async getVendor() {
      this.form = {
        ...this.form,
        ...(await this.$api.vendor.vendor('getBusinessDetail', this.$store.getters['vendor/params/getVendorId']))
      }
      this.form.businessId = this.form.id
      console.log('==========')
      console.log(this.form)

      this.areaList = this.areaList.map(item => {
        item.dis = this.form.areaIds.includes(item.id)
        return item
      })

      this.form.businessCategoryIds = []
      this.form.businessCategoryVOs.forEach(item => {
        let arr = []
        arr.push(item.parent.id || null)
        arr.push(item.id)
        this.form.businessCategoryIds.push(arr)
      })

      return
    },

    onSubmit() {
      this.$refs['form'].validate((valid, validObj) => {
        if (!valid) {
          this.$message.error(validObj[Object.keys(validObj)[0]][0].message)
          return
        }

        let arr = []
        this.form.businessCategoryIds.forEach(item => {
          arr.push(item[item.length - 1])
        })
        this.form.businessCategoryIds = arr
        this.form.businessCategoryVOs = []

        if (this.$store.getters['vendor/params/getVendorId']) {
          this.form.id = this.$store.getters['vendor/params/getVendorId']
          this.editForm('putBusiness', this.form, true)
        } else {
          this.editForm('postBusiness', this.form)
        }
      })
    },
    editForm(url, params, isEdit = false) {
      const loading = this.$loading({
        lock: true,
        text: '正在提交，请稍候'
      })
      this.$api.vendor
        .vendor(url, params)
        .then(res => {
          this.$message.success(`${isEdit ? '修改' : '添加'}${this.form.businessTitle}成功`)
          this.$router.push({
            path: '/vendor/vendorMange/list'
          })
        })
        .catch(err => {
          this.$message.error(err.msg || '操作失败')
        })
        .finally(() => {
          loading.close()
        })
    },
    handleCheckTitle() {
      if (!this.form.businessTitle) return
      this.$api.vendor
        .vendor('getBusinessTitle', {
          params: {
            businessTitle: this.form.businessTitle,
            businessId: this.form.id ? this.form.id : null
          }
        })
        .then(res => {
          if (res.length) {
            this.businessList = res.map(item => {
              let area = this.areaList.filter(c => item.areaIds.includes(c.id)) || []
              return {
                businessLogo: item.businessLogo,
                businessTitle: item.businessTitle,
                businessModel: +item.businessModel ? '普通收款' : '二级商户号',
                businessArea: area.map(item => item.name).join() || '--'
              }
            })
            this.handleShowList(true)
          }
        })
        .catch(err => {
          this.$message.error(err.msg || '获取商家有误')
        })
    }
  }
}
</script>

<style lang="less" scoped>
.container {
  margin: @container-margin;
  .vendorFrom {
    margin-left: 20px;
    .el-select,
    .el-input-number,
    .el-cascader,
    .el-input {
      width: 300px;
    }

    .assist {
      .assistText;
    }
    .img {
      width: 100px;
      height: 100px;
      border-radius: 6px;
    }
  }
}
/deep/ .el-tag__close {
  display: none;
}
</style>
