<template>
  <el-dialog
    :close-on-click-modal="false"
    title="商家信息"
    :visible="showList"
    width="50%"
    class="business_title"
    @close="handleCancel"
  >
    <p class="tip">当前已存在同名商家</p>
    <el-table :data="list" style="width: 100%">
      <el-table-column align="center" prop="businessTitle" label="商家名称"> </el-table-column>
      <el-table-column align="center" prop="businessLogo" label="商家LOGO">
        <template slot-scope="{ row }">
          <img class="avatar" :src="row.businessLogo | thumbSmall" />
        </template>
      </el-table-column>
      <el-table-column align="center" prop="businessArea" label="商家地区"> </el-table-column>
      <el-table-column align="center" prop="businessModel" label="收款方式"> </el-table-column>
    </el-table>
    <div slot="footer" class="dialog-footer">
      <el-button type="primary" @click="handleCancel">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    showList: {
      type: Boolean,
      default: false
    },
    list: {
      type: Array
    }
  },
  data() {
    return {
      bar: 'bar'
    }
  },
  computed: {
    ...mapGetters({})
  },
  watch: {},
  methods: {
    handleCancel() {
      this.$emit('set-dialog', false)
    }
  },
  created() {},
  mounted() {}
}
</script>

<style lang="less" scoped>
.business_title {
  width: 100%;
  .tip {
    color: #f56c6c;
    margin-bottom: 10px;
  }
  .avatar {
    display: inline-block;
    vertical-align: middle;
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}
</style>
